<template>
  <div class="flex flex-col min-h-[100vh] mx-auto">
    <gnb
      :isScrolled="isScrolled"
      customClassPublicPage="transition-all duration-300"
      contentClass="brand-page-gnb max-w-[136rem]"
      :showNotification="false"
    />
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';

import Gnb from '@/components/app/gnb-header.vue';

// We'll only use the manual class management approach
// This is more reliable for ensuring the class is removed when navigating away
onMounted(() => {
  document.body.classList.add('is-public-page');
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  document.body.classList.remove('is-public-page');
  window.removeEventListener('scroll', handleScroll);
});

const isScrolled = ref(false);

const handleScroll = () => {
  isScrolled.value = window.scrollY > 0;
};
</script>
